import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/sessions`, params);
}

export const CUSTOMER_SESSION_CSV_URL = `customer/sessions/csv`;

export function logout(id) {
  return $post(`customer/session/logout`, { id });
}
