<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <nb-download :url="CUSTOMER_SESSION_CSV_URL" :params="condition" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <ns-group v-model="condition.groupId" :company-id="condition.companyId" all-option />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-id v-model="condition.accountId" label="accountId" />
      <n-field-text v-model="condition.sessionId" label="sessionId" />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-12">{{ $t('label.company') }}</th>
            <th class="col-weight-12">{{ $t('label.customerId') }}</th>
            <th class="col-weight-12">{{ $t('label.accountId') }}</th>
            <th class="col-weight-12">{{ $t('label.app') }}</th>
            <th class="col-weight-17">{{ $t('label.ip') }}</th>
            <th class="col-weight-12">{{ $t('label.agent') }}</th>
            <th class="n-table-timestamp">{{ $t('label.loginTime') }}</th>
            <th class="n-table-timestamp">{{ $t('label.expireTime') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.profile" :value="row.customerId" />
            </td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.account" :value="row.accountId" />
            </td>
            <td class="n-ellipsis" :title="`${row.appName}:${row.appId}`">{{ `${row.appName}:${row.appId}` }}</td>
            <td class="n-ellipsis" :title="row.clientAddress">{{ row.clientAddress }}</td>
            <td class="n-ellipsis" :title="row.clientAgent">{{ row.clientAgent | hyphen }}</td>
            <td>{{ row.loginTime | datetime }}</td>
            <td>{{ row.expireTime | datetime }}</td>
            <td class="n-button-box">
              <nb-custom text="detail" @on-click="openModal(row)" />
              <nb-confirm text="logout" @on-ok="doLogout(row.id)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalCustomerSession from './ModalCustomerSession';
import { CUSTOMER_SESSION_CSV_URL, fetch, logout } from '@/api/customer/customer-customer-session';

export default CustomerView.extend({
  name: 'CustomerCustomerSession',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: 0,
        groupId: 0,
        customerId: null,
        account: null,
        sessionId: null,
        from: this.oneWeekAgo,
        to: this.eod,
      },
      CUSTOMER_SESSION_CSV_URL,
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
    openModal(model = {}) {
      this.createModal(ModalCustomerSession, { model, on: this });
    },
    doLogout(id) {
      return logout(id);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 1080px;

  th.rw-action {
    width: 8.5rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
